import { FC } from "react";
import Program from "./form-items/program";
import Medcenter, { MedcenterProps } from "./form-items/medcenter";
import Metro from "./form-items/metro";
import { Program as ProgramInterface } from "../../types/models/dms-contract";
import LpuFilial from "../../types/models/lpu-filial";

interface FiltersProps {
  programs: ProgramInterface[];
  lpuList: LpuFilial[];
  onMedcenterChange: MedcenterProps["onChange"];
}

const Filters: FC<FiltersProps> = ({
  programs,
  lpuList,
  onMedcenterChange,
}) => {

  return (
    <>
      <Program programs={programs} />
      <Medcenter programs={programs} onChange={onMedcenterChange} />
      <Metro lpuList={lpuList} programs={programs} />
    </>
  );
};

export default Filters;
