import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { FC } from "react";
import LpuFilial from "../../types/models/lpu-filial";
import { getFilialPhones } from "../../helpers/get-filial-phones";
import useFormWatch from "../../hooks/filters-form/use-watch";
import useLpuSupportsProgram from "../../hooks/use-lpu-supports-program";
import { Program } from "../../types/models/dms-contract";

interface FilialsMapProps {
  lpuList: LpuFilial[];
  programs: Program[];
}

const FilialsMap: FC<FilialsMapProps> = ({
  lpuList: initialLpuList,
  programs,
}) => {
  const metroList = useFormWatch("metro");
  const lpuSupportsProgram = useLpuSupportsProgram(programs);

  let lpuList = initialLpuList;

  if (metroList.length > 0) {
    // TODO: вынести в хук фильтра
    lpuList = lpuList.filter((lpu) => {
      return metroList.some((metro) => lpu.filialMetro.includes(metro));
    });
  }

  lpuList = lpuList.filter(lpuSupportsProgram);

  const getBalloonContent = (filial: LpuFilial) => {
    const phones = getFilialPhones(filial);

    return `<div>
        <b>Наименование:</b> ${filial.filialName}<br>
        <b>Метро:</b> ${filial.filialMetro || "нет"}<br>
        <b>Адрес:</b> ${filial.filialAddress}<br>
        <b>Телефоны:</b> ${phones.join(", ")}</div>`;
  };

  return (
    <YMaps
      query={{
        load: "GeoObjectCollection,Map,Placemark,Balloon,map.addon.balloon,geoObject.addon.balloon,route,geocode,coordSystem.geo,util.bounds",
      }}
    >
      <Map
        width={"100%"}
        height={"100%"}
        defaultState={{
          center: [55.75, 37.62],
          zoom: 11,
        }}
      >
        {lpuList.map((filial, i) => {
          const balloonContent = getBalloonContent(filial);

          return (
            <Placemark
              key={filial.filialFIASCode}
              geometry={[filial.filialLatitude, filial.filialLongitude]}
              properties={{
                balloonContent,
              }}
            />
          );
        })}
      </Map>
    </YMaps>
  );
};

export default FilialsMap;
