import { Program } from "../types/models/dms-contract";
import LpuFilial from "../types/models/lpu-filial";
import useFormWatch from "./filters-form/use-watch";

export default function useLpuSupportsProgram(programs: Program[]) {
  const programIndex = useFormWatch("program");
  const program = programs[programIndex];

  return function lpuSupportsProgram(lpu: LpuFilial): boolean {
    const format = (aidType: string) => aidType.replace(/(;$)|(^;)|(\s)/g, "");
    const formatedAidType1 = format(lpu.aidType1);
    const formatedAidType2 = format(lpu.aidType2);
    const aidTypes = [formatedAidType1, formatedAidType2].join(";");
    const programCodes = program.medcenters.map((item) => item.code);

    return programCodes.some((code) => aidTypes.includes(code));
  };
}
