import { NamePath } from "antd/es/form/interface";
import FiltersFormValues, {
  FiltersForm,
} from "../../types/filters-form-values";
import { FormInstance, useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

type ReturnPromise<T> = T extends Promise<infer ValueType> ? ValueType : never;

type GetGeneric<TForm extends FormInstance> = ReturnPromise<
  ReturnType<TForm["validateFields"]>
>;

function useFormWatch<TDependencies extends keyof GetGeneric<FiltersForm>>(
  dependencies: TDependencies | [TDependencies]
): GetGeneric<FiltersForm>[TDependencies];

function useFormWatch<
  TDependencies1 extends keyof GetGeneric<FiltersForm>,
  TDependencies2 extends keyof GetGeneric<FiltersForm>[TDependencies1]
>(
  dependencies: [TDependencies1, TDependencies2]
): GetGeneric<FiltersForm>[TDependencies1][TDependencies2];

function useFormWatch(dependencies: NamePath) {
  const form = useFormInstance<FiltersFormValues>();
  return useWatch(dependencies) || form.getFieldValue(dependencies);
}

export default useFormWatch;
