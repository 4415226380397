import LpuFilial from "../types/models/lpu-filial";

export default function getMetroList(lpuList: LpuFilial[], lpuSupportsProgram: (lpu: LpuFilial)=> boolean): string[] {
  const metroSet = new Set<string>();

  lpuList.forEach((lpu) => {
    if (lpuSupportsProgram(lpu)) {
      const metroList = lpu.filialMetro.split(",").map((item) => item.trim());
      metroList.forEach((item) => metroSet.add(item));
    }
  });

  const metroList: string[] = [];

  for (const metro of metroSet.values()) {
    metroList.push(metro);
  }

  return metroList.sort();
}
