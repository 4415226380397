import { Form, Select } from "antd";
import { FC } from "react";
import getMetroList from "../../../helpers/get-metro-list";
import LpuFilial from "../../../types/models/lpu-filial";
import useLpuSupportsProgram from "../../../hooks/use-lpu-supports-program";
import { Program } from "../../../types/models/dms-contract";

interface MetroProps {
  lpuList: LpuFilial[];
  programs: Program[]
}

const Metro: FC<MetroProps> = ({ lpuList, programs }) => {
  const lpuSupportsProgram = useLpuSupportsProgram(programs)
  const metroList = getMetroList(lpuList, lpuSupportsProgram);

  return (
    <Form.Item name={"metro"} label={"Метро"}>
      <Select
        mode={"multiple"}
        showSearch
        allowClear
        optionFilterProp={"children"}
      >
        {metroList.map((item, i) => {
          return (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default Metro;
