import { FC } from "react";
import Main from "../main/main";
import { Form } from "antd";
import FiltersFormValues from "../../types/filters-form-values";
import { useForm } from "antd/es/form/Form";

interface AppProps {}

const App: FC<AppProps> = () => {
  const [form] = useForm();

  const initialValues: Partial<FiltersFormValues> = {
    medcenter: [],
    metro: [],
  };

  return (
    <Form
      className={"filters"}
      form={form}
      layout={"vertical"}
      initialValues={initialValues}
      size={"large"}
    >
      <Main />
    </Form>
  );
};

export default App;
