import  { Program } from "../types/models/dms-contract";
import LpuFilial from "../types/models/lpu-filial";
import SuccessResponse from "../types/responses/success-response";

export default class Api {
  readonly DOMEN = "https://api.euro-ins.ru";

  readonly cache = new Map<number, LpuFilial[]>();

  async getDmsContractPrograms(contractIsn: string, subjectIsn: string) {
    // return dmsContract; // TODO: remove

    const url = `${this.DOMEN}/account/dms/contracts/${subjectIsn}/${contractIsn}/programs`;
    const res = await fetch(url);
    const data = await this.handleResponse<Program[]>(res);
    return data.data;
  }

  async getLpuList(medcenterIsn: number) {
    // return lpuList; // TODO: remove

    if (this.cache.has(medcenterIsn)) {
      return this.cache.get(medcenterIsn);
    } else {
      const url = `${this.DOMEN}/account/dms/lpu/info/${medcenterIsn}`;
      const res = await fetch(url);
      const data = await this.handleResponse<LpuFilial[]>(res);
      this.cache.set(medcenterIsn, data.data);
      return data.data;
    }
  }

  private statusIsCorrect(status: number) {
    return [200].includes(status);
  }

  private handleResponse<T>(response: Response): Promise<SuccessResponse<T>> {
    if (this.statusIsCorrect(response.status)) return response.json();
    else throw new Error(`Статус ответа сервера ${response.status}`);
  }
}
