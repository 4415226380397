import * as React from "react";
import { Form, Select } from "antd";
import { FC } from "react";
import { Program as ProgramInterface } from "../../../types/models/dms-contract";

interface ProgramProps {
  programs: ProgramInterface[];
}

const Program: FC<ProgramProps> = ({ programs }) => {
  // const onChange = () => {
  //   // если медцентр не выбран, то ставим любой
  // }

  return (
    <Form.Item name={"program"} label={"Программа"}>
      <Select>
        {programs.map((program, i) => {
          return (
            <Select.Option value={i} key={program.isn}>
              {program.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default Program;
